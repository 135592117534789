<template>
  <router-view></router-view>
</template>

<script setup lang="ts"></script>

<style>
* {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}
</style>
