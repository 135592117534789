<template>
  <div :class="currentTheme" class="root-wrap">
    <div class="header container">
      <!-- eslint-disable-next-line -->
      <img v-if="currentTheme === 'light-theme'" class="logo" src="../../assets/imgs/landing-page/light-text-logo.png" alt="" />
      <!-- eslint-disable-next-line -->
      <img v-else class="logo" src="../../assets/imgs/landing-page/dark-logo-text.png" alt="" />
      <div class="btn">Launch App</div>
    </div>
    <div class="data-wrap">
      <div class="container">
        <div class="title">
          <span>Unlock</span>
          <span>Greater</span>
          <span class="capital">Capital</span>
          <span>Control</span>
        </div>
        <!-- eslint-disable-next-line -->
        <div class="slogan">Swap, earn, and build on the leading decentralized crypto trading protocol on Injective.</div>
        <div class="btn-wrap">
          <div class="launch-btn">
            <span>LAUNCH APP</span>
            <!-- eslint-disable-next-line -->
            <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/icon-launch.png" alt="" />
            <!-- eslint-disable-next-line -->
            <img v-else src="../../assets/imgs/landing-page/chevron-right.png" alt="" />
          </div>
          <div class="read-btn">
            <span>Read Docs</span>
            <img src="../../assets/imgs/landing-page/icon-readdoc.png" alt="" />
          </div>
        </div>
        <div class="data-stats-wrap">
          <div class="data-stats">
            <p class="title">TOTAL VALUE LOCKED</p>
            <div class="num">$213,368,117</div>
            <div class="line"></div>
          </div>
          <div class="data-stats">
            <p class="title">TOTAL TRADING VOLUME</p>
            <div class="num">$75,246,607,846</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="built-on">
          <span>built on</span>
          <!-- eslint-disable-next-line -->
          <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/logo-injective.png" alt="" />
          <!-- eslint-disable-next-line -->
          <img v-else src="../../assets/imgs/landing-page/dark-injective-logo.png" alt="" />
        </div>
      </div>
    </div>
    <div class="operation-wrap">
      <div class="container">
        <div class="divider"></div>
        <div class="title">Driving DeFi Innovation on INJECTIVE</div>
        <div class="operation-list">
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/icon-trade.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/icon-trade-dark.png" alt="" />
              <div class="info">
                <div class="name">Trade</div>
                <div class="desc">Swap or trade quickly and cheaply.</div>
              </div>
            </div>
            <div class="btn">Enter Exchange</div>
            <div class="line"></div>
          </div>
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/icon-pool.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/icon-pool-dark.png" alt="" />
              <div class="info">
                <div class="name">Pool</div>
                <div class="desc">Provide liquidity for any token.</div>
              </div>
            </div>
            <div class="btn">Add Liquidity</div>
            <div class="line"></div>
          </div>
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/icon-leaderboard.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/icon-leaderboard-dark.png" alt="" />
              <div class="info">
                <div class="name">Leaderboard</div>
                <!-- eslint-disable-next-line -->
                <div class="desc">Real-time display of promotion ranking list.</div>
              </div>
            </div>
            <div class="btn">View Leaderboard</div>
            <div class="line"></div>
          </div>
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/icon-referrals.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/icon-referrals-dark.png" alt="" />
              <div class="info">
                <div class="name">Referrals</div>
                <!-- eslint-disable-next-line -->
                <div class="desc">Stake Injective (INJ) to earn referral codes.</div>
              </div>
            </div>
            <div class="btn">Get Referral Code</div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="intro-wrap">
      <div class="container">
        <div class="divider"></div>
        <!-- eslint-disable-next-line -->
        <div class="title">SamuraiDEX provides ecosystem-wide liquidity for users and projects</div>
        <div class="intro-list top-list">
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/intro-icon-01.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/intro-icon-01-dark.png" alt="" />
              <div class="info">
                <div class="name">Best Price Swaps</div>
                <!-- eslint-disable-next-line -->
                <div class="desc">Guarantee optimal trading outcomes with our algorithm ensuring you always get the best available price on your swaps.</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/intro-icon-02.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/intro-icon-02-dark.png" alt="" />
              <div class="info">
                <div class="name">Permissionless Security</div>
                <!-- eslint-disable-next-line -->
                <div class="desc">Enjoy robust security without the need for permissions, enabling open access while safeguarding your assets and data.</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/intro-icon-03.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/intro-icon-03-dark.png" alt="" />
              <div class="info">
                <div class="name">MEV Protection</div>
                <!-- eslint-disable-next-line -->
                <div class="desc">Secure transactions against front-running & maximize trade value with Maximal Extractable Value (MEV) protection.</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
        <div class="intro-list bottom-list">
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/intro-icon-04.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/intro-icon-04-dark.png" alt="" />
              <div class="info">
                <div class="name">Bot Mitigation</div>
                <!-- eslint-disable-next-line -->
                <div class="desc">Combat unauthorized trading bots and ensure a fair trading environment through sophisticated bot mitigation techniques.</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
          <div class="item">
            <div class="top-info">
              <!-- eslint-disable-next-line -->
              <img v-if="currentTheme === 'light-theme'" src="../../assets/imgs/landing-page/intro-icon-05.png" alt="" />
              <!-- eslint-disable-next-line -->
              <img v-else src="../../assets/imgs/landing-page/intro-icon-05-dark.png" alt="" />
              <div class="info">
                <div class="name">Decentralized Launchpad</div>
                <!-- eslint-disable-next-line -->
                <div class="desc">Access emerging crypto projects and participate in decentralized token
                  launches on a transparent and equitable platform.</div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="container">
        <!-- eslint-disable-next-line -->
        <img v-if="currentTheme === 'light-theme'" class="logo" src="../../assets/imgs/landing-page/light-text-logo.png" alt="" />
        <!-- eslint-disable-next-line -->
        <img v-else class="logo" src="../../assets/imgs/landing-page/dark-logo-text.png" alt="" />
        <div class="nav-list">
          <div class="title">About</div>
          <div class="link">Documentation</div>
          <div class="link">Disclaimer</div>
        </div>
        <div class="nav-list">
          <div class="title">Support</div>
          <div class="link">FAQs</div>
        </div>
        <div class="nav-list">
          <div class="title">Follow Us On</div>
          <div v-if="currentTheme === 'light-theme'" class="icons">
            <!-- eslint-disable-next-line -->
            <img src="../../assets/imgs/landing-page/light-youtube.png" alt="" />
            <!-- eslint-disable-next-line -->
            <img src="../../assets/imgs/landing-page/light-facebook.png" alt="" />
            <!-- eslint-disable-next-line -->
            <img src="../../assets/imgs/landing-page/light-twitter.png" alt="" />
          </div>
          <div v-else class="icons">
            <!-- eslint-disable-next-line -->
            <img src="../../assets/imgs/landing-page/dark-youtube.png" alt="" />
            <!-- eslint-disable-next-line -->
            <img src="../../assets/imgs/landing-page/dark-facebook.png" alt="" />
            <!-- eslint-disable-next-line -->
            <img src="../../assets/imgs/landing-page/dark-twitter.png" alt="" />
          </div>
          <div class="link">© 2024 SamuraiDEX.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function resize() {
  let windowSize = {
    width: window.screen.width,
    height: document.documentElement.clientHeight,
    ratio: window.devicePixelRatio,
    scale: window.screen.width / 1440,
  };
  let str =
    "width=1440, initial-scale=" +
    windowSize.scale +
    ", maximum-scale=" +
    windowSize.scale +
    ", minimum-scale=" +
    windowSize.scale +
    ", user-scalable=no";
  document.getElementById("viewport") &&
    document.getElementById("viewport").setAttribute("content", str);
}

window.addEventListener("resize", resize());

export default {
  name: "LadingPage",
  data() {
    return { theme: "light" };
  },
  computed: {
    currentTheme() {
      const currentTime = new Date().getHours();
      if (currentTime >= 7 && currentTime < 19) {
        return "light-theme";
      } else {
        return "dark-theme";
      }
    },
  },
  created() {
    // eslint-disable-next-line
    const isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    if (isMobile) {
      this.$router.replace("/landing-mobile");
    } else {
      this.$router.replace("/");
    }
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.root-wrap {
  background: linear-gradient(to right, #d5e7e6, #fff, #e5e4d6);

  .container {
    width: 81.25%;
    max-width: 1170px;
    margin: 0 auto;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 25px;

    img {
      width: 240px;
      height: 42px;
    }

    .btn {
      width: 170px;
      height: 50px;
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      line-height: 50px;
      text-align: center;
      border-radius: 6px;
      background-color: #151028;
      overflow: hidden;
      cursor: pointer;
    }
  }

  .data-wrap {
    // min-width: 1170px;
    padding-top: 95px;
    background: url(../../assets/imgs/landing-page/light-symbol.png) no-repeat;
    background-size: 570px 612px;
    background-position: center top 60px;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        font-size: 60px;
        font-family: "Roboto Slab", serif;
        font-weight: 700;
        line-height: 72px;
        margin-left: 12px;
        color: #000;
      }

      .capital {
        color: #990033;
      }

      span:first-of-type {
        margin-left: 0;
      }
    }

    .slogan {
      font-size: 24px;
      font-weight: 600;
      color: #000;
      line-height: 30px;
      margin-top: 20px;
      text-align: center;
    }

    .btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 70px;

      div {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 470px;
        height: 66px;
        border-radius: 6px;
        margin: 0 12px;
        background-color: #000;
        box-shadow: 0px 10px 15px 0px rgba(5, 13, 29, 0.18);
        cursor: pointer;
        overflow: hidden;

        span {
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }

        img {
          widows: 20px;
          height: 20px;
          margin-left: 12px;
        }
      }

      .read-btn {
        background-color: #fff;
        border: 1px solid #dfe4ea;

        span {
          color: #000;
        }
      }
    }

    .data-stats-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 70px;

      .data-stats {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 470px;
        height: 146px;
        border-radius: 6px;
        margin: 0 12px;
        padding: 20px;
        background-color: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.4);
        cursor: pointer;
        overflow: hidden;

        .title {
          font-size: 16px;
          font-weight: 700;
          color: rgba(99, 115, 129, 1);
        }

        .num {
          font-size: 40px;
          font-weight: 700;
          color: rgba(21, 16, 40, 1);
          line-height: 48px;
          margin: 10px 0;
        }

        .line {
          width: 100%;
          height: 7px;
          border-radius: 7px;
          background-color: rgba(223, 228, 234, 1);
        }
      }
    }

    .built-on {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 70px;

      span {
        font-size: 14px;
        font-weight: 400;
        color: #000;
      }

      img {
        width: 146px;
        height: 30px;
        margin-left: 10px;
      }
    }
  }

  .operation-wrap {
    margin-top: 140px;

    .divider {
      width: 250px;
      height: 0;
      margin: 0 auto;
      border: 1px solid rgba(21, 16, 40, 1);
    }

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: center;
      margin: 30px auto;
    }

    .operation-list {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // width: 270px;
        flex: 1;
        height: 202px;
        padding: 20px;
        margin-left: 30px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.4);
        overflow: hidden;

        .top-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex: 1;
          max-height: 60px;

          img {
            min-width: 50px;
            width: 50px;
            height: 50px;
            margin-right: 14px;
            border-radius: 3px;
          }

          .info {
            flex: 1;
            box-sizing: border-box;
            padding-right: 25px;

            .name {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
              color: rgba(21, 16, 40, 1);
            }

            .desc {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: #000;
            }
          }
        }

        .btn {
          width: 100%;
          height: 50px;
          font-size: 16px;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.75);
          text-align: center;
          line-height: 50px;
          background: rgba(21, 16, 40, 1);
          border-radius: 6px;
          cursor: pointer;
        }

        .line {
          width: 100%;
          height: 7px;
          min-height: 7px;
          border-radius: 7px;
          background-color: rgba(223, 228, 234, 1);
        }
      }

      .item:first-of-type {
        margin-left: 0;
      }
    }
  }

  .intro-wrap {
    margin-top: 140px;
    padding-bottom: 188px;

    .divider {
      width: 250px;
      height: 0;
      margin: 0 auto;
      border: 1px solid rgba(21, 16, 40, 1);
    }

    .title {
      font-size: 24px;
      font-weight: 600;
      line-height: 30px;
      letter-spacing: 0px;
      text-align: center;
      margin: 30px auto;
    }

    .intro-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(1, auto);
      gap: 30px;
      align-items: start;
      justify-items: start;

      .item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // min-width: 371px;
        // height: 154px;
        padding: 20px;
        // margin-left: 30px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.4);
        overflow: hidden;

        .top-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          img {
            min-width: 50px;
            width: 50px;
            height: 50px;
            margin-right: 14px;
            border-radius: 3px;
          }

          .info {
            flex: 1;
            box-sizing: border-box;

            .name {
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              text-align: left;
              color: rgba(21, 16, 40, 1);
            }

            .desc {
              font-size: 14px;
              font-weight: 400;
              line-height: 22px;
              text-align: left;
              color: #000;
            }
          }
        }

        .line {
          width: 100%;
          height: 7px;
          margin-top: 20px;
          border-radius: 7px;
          background-color: rgba(223, 228, 234, 1);
        }
      }

      .item:first-of-type {
        margin-left: 0;
      }
    }

    .bottom-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(1, auto);
      gap: 30px;
      align-items: start;
      justify-content: start;
      margin-top: 30px;

      .item {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        // min-width: 570px;
        padding: 20px;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.75);
        box-shadow: 0px 1px 3px 0px rgba(166, 175, 195, 0.4);
        overflow: hidden;

        .line {
          width: 100%;
        }
      }
    }
  }

  .footer {
    padding-top: 120px;
    padding-bottom: 164px;
    background-color: #fff;
    border-top: 1px solid rgba(223, 228, 234, 1);

    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .logo {
        width: 240px;
        height: 42px;
      }

      .nav-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .title {
          font-size: 18px;
          font-weight: 600;
          line-height: 26px;
          color: rgba(21, 16, 40, 1);
          margin-bottom: 35px;
        }

        .link {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #000;
          margin-bottom: 12px;
          cursor: pointer;
        }

        .icons {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 25px;

          img {
            width: 32px;
            height: 32px;
            margin-right: 15px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.dark-theme {
  background: linear-gradient(101.45deg, #151028 0%, #000000 100%);

  .header {
    .btn {
      color: rgba(21, 16, 40, 1);
      background-color: rgba(213, 231, 230, 1);
    }
  }

  .data-wrap {
    background-image: url(../../assets/imgs/landing-page/dark-symbol.png);

    .container {
      .title {
        span {
          color: rgba(229, 228, 214, 1);
        }

        .capital {
          color: rgba(153, 0, 51, 1);
        }
      }

      .slogan {
        color: rgba(229, 228, 214, 1);
      }

      .data-stats-wrap {
        .data-stats {
          background-color: rgba(21, 16, 40, 1);

          .title {
            color: rgba(229, 228, 214, 1);
          }

          .num {
            color: rgba(229, 228, 214, 1);
          }

          .line {
            background-color: rgba(223, 228, 234, 1);
          }
        }
      }

      .btn-wrap {
        .launch-btn {
          background-color: rgba(213, 231, 230, 1);

          span {
            color: #000;
          }
        }
      }

      .built-on {
        span {
          color: rgba(213, 231, 230, 1);
        }
      }
    }
  }

  .operation-wrap {
    .divider {
      border-color: rgba(229, 228, 214, 1);
    }

    .title {
      color: rgba(229, 228, 214, 1);
    }

    .operation-list {
      .item {
        background-color: rgba(21, 16, 40, 1);

        .top-info {
          .info {
            .name {
              color: rgba(229, 228, 214, 1);
            }

            .desc {
              color: rgba(229, 228, 214, 1);
            }
          }
        }

        .btn {
          color: rgba(21, 16, 40, 1);
          background-color: rgba(213, 231, 230, 1);
        }

        .line {
          background-color: rgba(229, 228, 214, 1);
        }
      }
    }
  }

  .intro-wrap {
    .divider {
      border-color: rgba(229, 228, 214, 1);
    }

    .title {
      color: rgba(229, 228, 214, 1);
    }

    .intro-list {
      .item {
        background-color: rgba(21, 16, 40, 1);

        .top-info {
          .info {
            .name {
              color: rgba(229, 228, 214, 1);
            }

            .desc {
              color: rgba(229, 228, 214, 1);
            }
          }
        }

        .line {
          background-color: rgba(229, 228, 214, 1);
        }
      }
    }
  }

  .footer {
    border-top: 1px solid rgba(229, 228, 214, 1);
    background: #000;

    .container {
      .nav-list {
        .title {
          color: rgba(229, 228, 214, 1);
        }

        .link {
          color: rgba(213, 231, 230, 1);
        }
      }
    }
  }
}

@media screen and (min-width: 1230px) and (max-width: 1440px) {
  .root-wrap {
    .container {
      width: 96%;
      margin: 0 auto;
    }
  }
}

@media screen and (min-width: 900px) and (max-width: 1229px) {
  .root-wrap {
    .container {
      width: 96%;
      margin: 0 auto;
    }

    .operation-wrap {
      .operation-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 30px;

        .item {
          margin-left: 0;
        }
      }
    }

    // .intro-wrap {
    //   .intro-list {
    //     display: grid;
    //     grid-template-columns: repeat(3, 1fr);
    //     // grid-template-rows: repeat(2, 1fr);
    //     gap: 30px;

    //     .item {
    //       width: 100%;
    //       margin-left: 0;
    //       margin-top: 30px;
    //     }
    //   }
    // }
  }
}

@media screen and (min-width: 750px) and (max-width: 899px) {
  .root-wrap {
    .container {
      width: 96%;
      margin: 0 auto;
    }

    .data-wrap {
      .container {
        .title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .operation-wrap {
      .operation-list {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 30px;

        .item {
          margin-left: 0;
        }
      }
    }

    .intro-wrap {
      .title {
        max-width: 570px;
      }

      .intro-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .item {
          width: 100%;
          max-width: 570px;
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }

    .footer {
      width: 100%;
      overflow-x: hidden;

      .container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20%;

        .nav-list {
          margin-top: 50px;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .root-wrap {
    .container {
      width: 96%;
      margin: 0 auto;
    }

    .data-wrap {
      .container {
        .title {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        .btn-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          div {
            width: 100%;
            margin-bottom: 30px;
          }

          .read-btn {
            margin-bottom: 0;
          }
        }

        .data-stats-wrap {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .data-stats {
            width: 100%;
            margin-bottom: 30px;
          }

          .data-stats:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }

    .operation-wrap {
      .operation-list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .item {
          width: 100%;
          height: 202px;
          flex: none;
          margin-left: 0;
          margin-bottom: 30px;
        }

        .item:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    .intro-wrap {
      .title {
        max-width: 570px;
      }

      .intro-list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        .item {
          width: 100%;
          max-width: 570px;
          margin-left: 0;
          margin-top: 30px;
        }
      }
    }

    .footer {
      width: 100%;
      overflow-x: hidden;

      .container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding-left: 20%;

        .nav-list {
          margin-top: 50px;
        }
      }
    }
  }
}
</style>
